import React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import Loadable from 'react-loadable';
import '../styles/reset.css';
import { SEO } from '../components/seo';

const Loader = () => {
  return (
    <>
      <div className="loader__wrapper">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="loading-logo-svg"
        >
          <g clip-path="url(#clip0_279_85)">
            <path
              d="M14.0676 25.519C13.9269 25.7034 13.7409 25.8483 13.5276 25.9397C13.3144 26.0311 13.0812 26.0659 12.8506 26.0407C12.62 26.0154 12.3998 25.9311 12.2114 25.7958C12.0229 25.6604 11.8727 25.4787 11.7751 25.2683L0.147626 2.01335C0.0414175 1.80167 -0.00893727 1.56636 0.00133994 1.32975C0.0116172 1.09314 0.0821855 0.863085 0.206348 0.661411C0.330511 0.459736 0.504149 0.293135 0.710784 0.177416C0.91742 0.061697 1.1502 0.000699997 1.38703 0.000213623H17.5781C17.8402 -0.00479507 18.098 0.0672035 18.3195 0.207281C18.5411 0.347361 18.7167 0.549368 18.8246 0.788279C22.3136 7.95245 20.2002 18.9638 14.0676 25.519Z"
              fill="#2E9EEC"
            />
            <path
              d="M31.0038 0.753952C33.9263 5.27254 35.6295 10.4773 35.9461 15.8572C36.2626 21.2371 35.1818 26.6073 32.8096 31.4407L24.9001 47.216C24.7848 47.4504 24.6068 47.6479 24.3861 47.7863C24.1654 47.9248 23.9107 47.9988 23.6505 48H7.32588C7.09706 47.981 6.87602 47.9075 6.68111 47.7855C6.48619 47.6636 6.32301 47.4967 6.20509 47.2987C6.08717 47.1007 6.0179 46.8773 6.00303 46.647C5.98816 46.4167 6.02812 46.1862 6.11959 45.9745L28.6201 0.753952C28.7279 0.528159 28.897 0.337576 29.1078 0.204178C29.3187 0.0707798 29.5628 0 29.812 0C30.0611 0 30.3052 0.0707798 30.5161 0.204178C30.727 0.337576 30.896 0.528159 31.0038 0.753952Z"
              fill="#2E9EEC"
            />
            <path
              d="M47.3644 1.00075C47.3645 1.0005 47.3646 1.00025 47.3647 1C47.6685 0.375521 47.5001 0 45.9999 0H31C33.1075 0.0438657 38.5345 12.3255 36.5511 23.2281C36.5346 23.3188 36.5175 23.4095 36.5 23.5C36.5 23.5 47.0525 1.64186 47.3644 1.00075Z"
              fill="#2E9EEC"
            />
          </g>
          <defs>
            <clipPath id="clip0_279_85">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};

const LandingPageLazyLoader = Loadable({
  loader: () => new Promise<React.ComponentType>((resolve) => {
    import('./LandingPage').then((component) => {
      setTimeout(() => {
        resolve(component.default);
      }, 2000);
    });
  }),
  loading: Loader,
});

const IndexPage: React.FC<PageProps> = () => {
  return <LandingPageLazyLoader />;
};

export default IndexPage;

export const Head: HeadFC = () => (
  <SEO /> 
);
